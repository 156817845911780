import { useState, useEffect } from 'react'
import {
    FormLabel,
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import {
    TextField,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { styles } from '../../../styles/styles'
import { Controller } from 'react-hook-form'

const DisplayOptions = ({ register, control }) => {
    const style = styles()
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-displayOptions-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Options
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowLookingFor'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Looking For?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('options.isShowDiet')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Diet?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowChildren'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Children?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowMarriage'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Marriage?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowExercise'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Exercise?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('options.isShowPet')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Pet?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowVaccinationStatus'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Vaccination Status?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowPolitics'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Politics?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowReligion'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Religion?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowSmoking'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Smoking?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowDrinking'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Drinking?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowEducation'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Education?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowSalary'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Salary?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowIndustry'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Industry?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowEthnicity'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Ethnicity?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowSexualOrientation'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Sexual Orientation?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowGender'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show Gender?"
                                />
                            </FormGroup>
                        </Grid>
                        {/* <Grid item xs={2} 
                        // style={{ paddingTop: '30px' }}
                        >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Controller
                                {...register('options.isShowInstagram')}
                                control={control}
                                render={({ field }) => (
                                    <Checkbox
                                        {...field}
                                        checked={field?.value || false}
                                        onChange={(e) =>
                                            field.onChange(e.target.checked)
                                        }
                                    />
                                )}
                            />
                        }
                        label="Show Instagram?"
                    />
                </FormGroup>
            </Grid> */}
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'options.isShowLinkedin'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Show LinkedIn?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('isMobileVerified')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Is Mobile Verified?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    {...register(
                                                        'isEmailVerified'
                                                    )}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            checked={
                                                                field?.value ||
                                                                false
                                                            }
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Is Email Verified?"
                                        />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('isFunder')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Is Founder?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('isFake')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Is Fake?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('matchMakeMe')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="MatchMake Me User?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'isCommunityCodeAgrement'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Community Code Agreement?"
                                />
                            </FormGroup>
                        </Grid>
                        {/* <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('relocateForLove')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Relocate for love?"
                                />
                            </FormGroup>
                        </Grid> */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default DisplayOptions
