import React from 'react'
import {
    FormLabel,
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { styles } from '../../../styles/styles'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { Controller } from 'react-hook-form'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { VACCINATIONSTATUSDROPDOWN } from '../../../Utils/index'
import {
    EthnicityDropdownValues,
    PoliticsDropdownValues,
    ReligionDropdownValues,
} from '../../../Utils/advancedPreferenceDropDown'

const EthnicityAndReligion = ({ register, valuesDropdownOptions, control }) => {
    const style = styles()
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-ethnicity-religion-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Ethnicity & Religion
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={2}>
                        {/* <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="ethnicity"
                                        sx={{ pt: 4 }}
                                    >
                                        Ethnicity:
                                    </FormLabel>
                                    <TextField
                                        {...register('ethnicity')}
                                        placeholder="Ethnicity"
                                        id="ethnicity"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid> */}

                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="ethnicity"
                                        sx={{ pt: 4 }}
                                    >
                                        Ethnicity:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('ethnicity')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="ethnicity"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field.value}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : []
                                                        }
                                                        //defaultValue={valuesVal}
                                                        multiple={false}
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Values
                                                        </MenuItem>
                                                        {EthnicityDropdownValues?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                        key={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* <Grid item container xs={4}>
                <Grid item xs={12}>
                    <Stack direction="column">
                        <FormLabel
                            htmlFor="ethnicityDescription"
                            sx={{ pt: 4 }}
                        >
                            Ethnicity Description:
                        </FormLabel>
                        <TextField
                            {...register('ethnicityDescription')}
                            placeholder="Ethnicity Description"
                            id="ethnicityDescription"
                            // value={""}
                            // onChange={() => {}}
                            fullWidth
                            margin="normal"
                            size="small"
                        />
                    </Stack>
                </Grid>
            </Grid> */}
                        {/* <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="religion"
                                        sx={{ pt: 4 }}
                                    >
                                        Religion:
                                    </FormLabel>
                                    <TextField
                                        {...register('religion')}
                                        placeholder="Religion"
                                        id="religion"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid> */}

                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="religion"
                                        sx={{ pt: 4 }}
                                    >
                                        Religion:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('religion')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="religion"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field.value}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : []
                                                        }
                                                        //defaultValue={valuesVal}
                                                        multiple={false}
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Values
                                                        </MenuItem>
                                                        {ReligionDropdownValues?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                        key={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="politics"
                                        sx={{ pt: 4 }}
                                    >
                                        Politics:
                                    </FormLabel>
                                    <TextField
                                        {...register('politics')}
                                        placeholder="Politics"
                                        id="politics"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid> */}
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="politics"
                                        sx={{ pt: 4 }}
                                    >
                                        Politics:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('politics')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="politics"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field.value}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : []
                                                        }
                                                        //defaultValue={valuesVal}
                                                        multiple={false}
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Values
                                                        </MenuItem>
                                                        {PoliticsDropdownValues?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                        key={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="values" sx={{ pt: 4 }}>
                                        Values:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('values')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="values"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field.value}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : []
                                                        }
                                                        //defaultValue={valuesVal}
                                                        multiple={true}
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Values
                                                        </MenuItem>
                                                        {valuesDropdownOptions?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option.name
                                                                        }
                                                                        key={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                    {/* <TextField
                            {...register('values')}
                            placeholder="Values"
                            id="values"
                            // value={""}
                            // onChange={() => {}}
                            fullWidth
                            margin="normal"
                            size="small"
                        /> */}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="vaccinationStatus"
                                        sx={{ pt: 4 }}
                                    >
                                        Vaccination Status:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register(
                                                    'vaccinationStatus'
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="vaccinationStatus"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field.value}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : []
                                                        }
                                                        //defaultValue={valuesVal}
                                                        multiple={false}
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Values
                                                        </MenuItem>
                                                        {VACCINATIONSTATUSDROPDOWN?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                        key={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="relocateForLove"
                                        sx={{ pt: 4 }}
                                    >
                                        Would you relocate for love?
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('relocateForLove')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="relocateForLove"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field.value}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : []
                                                        }
                                                        //defaultValue={valuesVal}
                                                        multiple={false}
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Value
                                                        </MenuItem>
                                                        {['Yes', 'No']?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                        key={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="closestFriendsDescribeYou"
                                        sx={{ pt: 4 }}
                                    >
                                        How would your closest friends describe
                                        you?
                                    </FormLabel>
                                    <TextField
                                        {...register(
                                            'closestFriendsDescribeYou'
                                        )}
                                        placeholder="How would your closest friends describe
                                        you"
                                        id="closestFriendsDescribeYou"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default EthnicityAndReligion
