import { FormLabel, Grid, Typography } from '@material-ui/core'
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Stack,
    TextField,
} from '@mui/material'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { ContentState, EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { styles } from '../../styles/styles'
import {
    add_hub,
    get_all_dropdowns,
    get_hub_details_by_id,
} from '../../../src/api/UserRequest_Api'
import { get_all_quiz_names } from '../../../src/api/Quiz_Module_Api'
import UserImageUploadSection from './UserImageUpload'
import { Button as ButtonCore } from '@material-ui/core'
import imageCompression from 'browser-image-compression'

import Store from '../../redux/Store'
import { set_message } from '../../redux/ActionCreators'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import JoditEditor from 'jodit-react'
import SubtypeSelect from './SelectParentChildDropdown'
const BlogModule = () => {
    const editorJodit = useRef(null)
    const [contentJodit, setContentJodit] = useState('')
    console.log('contentJodit', contentJodit)
    const history = useHistory()
    const style = styles()
    const params = useParams()
    console.log('params', params)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [contentState, setContentState] = useState('')
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [blogData, setBlogData] = useState(null)
    const [blobImageLocalURL, setBlobImageLocalURL] = useState(null)
    const [userImages, setUserImages] = useState(null)
    const [hubStatusDropdown, setHubStatusDropdown] = useState([])
    const [hubTypesDropdown, setHubTypesDropdown] = useState([])
    const [hubLocationDropdownValues, setHubLocationDropdownValues] = useState(
        []
    )
    const [hubTagsDropdownValues, setHubTagsDropdownValues] = useState([])
    const [quizDropdown, setQuizDropdown] = useState([])
    const [categoryDropdown, setCategoryDropdown] = useState([])
    const [subTypeCategory, setSubTypeCategory] = useState([])
    const handleSetUserImages = (imagesArr) => {
        setUserImages(imagesArr)
    }
    const form = useForm({ shouldUnregister: true })

    const {
        register,
        setValue,
        control,
        watch,
        handleSubmit,
        reset,
        getValues,
        setError,
        setFocus,
        values,
        formState: { errors },
    } = form
    console.log('form values', getValues())
    const validationAddUser = (data) => {
        let message = ''
        if (
            data.status.toUpperCase() === 'DRAFT' ||
            data.status.toUpperCase() === 'UNPUBLISHED'
        ) {
            if (!data.title) {
                message = 'Title is required'
            }
        } else if (data.status.toUpperCase() === 'PUBLISHED') {
            if (!data.title) {
                message = 'Title is required'
            } else if (!data?.category) {
                message = 'Category is required'
            } else if (!data?.type) {
                message = 'Hub Type is required'
            } else if (!contentJodit) {
                message = 'Description is required'
            } else if (
                data?.type?.toLowerCase() === 'quiz' &&
                (data?.button_title === null ||
                    data?.button_title === '' ||
                    data?.button_title === undefined)
            ) {
                message = 'Button title is required'
            } else if (data?.button_link) {
                if (
                    data?.button_link?.toLowerCase()?.startsWith('http://') ||
                    data?.button_link?.toLowerCase()?.startsWith('https://') ||
                    data?.button_link?.toLowerCase()?.startsWith('reddi')
                ) {
                } else if (data?.type?.toLowerCase() === 'quiz') {
                } else {
                    message = 'Button link is not valid'
                }
            } else if (
                data?.type?.toLowerCase() === 'quiz' &&
                (data?.button_link === null ||
                    data?.button_link === '' ||
                    data?.button_link === undefined)
            ) {
                message = 'Button link is required'
            }

            if (
                contentJodit &&
                contentJodit.trim().length === 11 &&
                contentJodit.trim() == '<p><br></p>' &&
                message === ''
            ) {
                message = 'Description is required'
            }
            if (blobImageLocalURL === null && message === '') {
                message = 'Image is required'
            }
        }

        if (message) {
            Store.dispatch(
                set_message({
                    mode: true,
                    message: message,
                    color: 'error',
                })
            )
            return true
        }
    }
    const onSubmit = async (data) => {
        if (validationAddUser(data)) return
        let compressedFile = null
        setButtonLoading(true)
        if (userImages) {
            console.log(`originalFile size ${userImages.size / 1024 / 1024} MB`)
            const options = {
                maxSizeMB: 1,
                useWebWorker: true,
            }
            compressedFile = userImages
            if (compressedFile?.size > 3145728) {
                compressedFile = await imageCompression(userImages, options)
            }
            console.log(
                `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
            )
            console.log('userImages_userImages', editorState)
        } else {
            if (data?.image && blobImageLocalURL) {
                compressedFile = data?.image ?? null
            } else {
                compressedFile = null
            }
        }

        let buttonLink = data?.button_link
        if (
            data?.button_link === 'attachment_style_quiz' &&
            data.type?.toLowerCase() === 'quiz' &&
            data?.status?.toUpperCase() === 'PUBLISHED'
        ) {
            buttonLink = 'reddi://attachment-style-quiz'
        } else if (
            data?.button_link &&
            data?.button_link !== 'attachment_style_quiz' &&
            data.type?.toLowerCase() === 'quiz'
        ) {
            buttonLink = 'reddi://quiz/' + buttonLink
        }
        const formData = new FormData()
        formData.append('image', compressedFile)

        formData.append('title', data?.title ? data?.title : null)
        formData.append('category', data?.category ? data?.category : null)
        formData.append('type', data?.type?.toLowerCase() ?? null)
        // formData.append(
        //     'short_description',
        //     data?.short_description ? data?.short_description : null
        // )
        // formData.append('description', contentState ? contentState : null)
        formData.append('description', contentJodit ? contentJodit : null)
        formData.append('status', data?.status?.toUpperCase())
        formData.append('button_link', buttonLink ?? null)
        formData.append('button_title', data?.button_title ?? null)
        formData.append('subtype_category', data?.subtype_category ?? null)
        formData.append('location', data?.location ?? null)
        formData.append('tag', data?.tag ?? null)
        if (params?.id !== 'add') {
            formData.append('id', params?.id)
        }
        await add_hub(formData)
        setButtonLoading(false)
        history.push('/hub')
    }
    useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const blogCategoryDropdown = res?.find((d) => {
                    if (d.dropdownTitle === 'hub_category') {
                        return true
                    }
                })
                setCategoryDropdown(blogCategoryDropdown?.optionList)
                setSubTypeCategory(blogCategoryDropdown?.subtype_categories)
                const hubStatusDropdown = res?.find((d) => {
                    if (d.dropdownTitle === 'hub_status') {
                        return true
                    }
                })
                setHubStatusDropdown(hubStatusDropdown?.optionList)
                const hubTypesDropdown = res?.find((d) => {
                    if (d.dropdownTitle === 'hub_types') {
                        return true
                    }
                })
                setHubTypesDropdown(hubTypesDropdown?.optionList)
                const hubLocation = res?.find((d) => {
                    if (d.dropdownTitle === 'hub_locations') {
                        return true
                    }
                })
                setHubLocationDropdownValues(hubLocation?.optionList)
                const hubTags = res?.find((d) => {
                    if (d.dropdownTitle === 'hub_tags') {
                        return true
                    }
                })
                setHubTagsDropdownValues(hubTags?.optionList)
            })
            .catch((err) => {})
        get_all_quiz_names({ type: 'HUB_QUIZ' }).then((res) => {
            if (Array.isArray(res?.data?.allActiveQuiz)) {
                res?.data?.allActiveQuiz?.unshift({
                    _id: 'attachment_style_quiz',
                    type: 'attachment_style_quiz',
                    title: 'Attachment Style Quiz',
                })
            }
            setQuizDropdown(res?.data?.allActiveQuiz)
        })
        if (params?.id?.toLowerCase() !== 'add') {
            setLoading(true)
            get_hub_details_by_id(params?.id)
                .then((res) => {
                    if (res?.data) {
                        for (const key in res?.data) {
                            if (Object.hasOwnProperty.call(res?.data, key)) {
                                if (key?.toUpperCase() === 'STATUS') {
                                    if (
                                        res?.data[key]?.toUpperCase() ===
                                        'DRAFT'
                                    ) {
                                        setValue(key, 'Draft')
                                    } else if (
                                        res?.data[key]?.toUpperCase() ===
                                        'PUBLISHED'
                                    ) {
                                        setValue(key, 'Published')
                                    } else if (
                                        res?.data[key]?.toUpperCase() ===
                                        'UNPUBLISHED'
                                    ) {
                                        setValue(key, 'Unpublished')
                                    }
                                } else if (
                                    key === 'button_link' &&
                                    res?.data['type']?.toLowerCase() === 'quiz'
                                ) {
                                    if (
                                        res?.data[key]?.startsWith(
                                            'reddi://quiz'
                                        )
                                    ) {
                                        const quizId =
                                            res?.data[key].split('/')[
                                                res?.data[key].split('/')
                                                    .length - 1
                                            ]
                                        setValue(key, quizId)
                                    } else if (
                                        res?.data[key]?.startsWith(
                                            'reddi://attachment-style-quiz'
                                        )
                                    ) {
                                        setValue(key, 'attachment_style_quiz')
                                    }
                                } else {
                                    setValue(key, res?.data[key])
                                }
                            }
                        }
                        setBlobImageLocalURL(res?.data?.image)
                        setContentJodit(res?.data?.description)
                        // const contentBlock = htmlToDraft(res?.data?.description)
                        // if (contentBlock) {
                        //     const contentState1 =
                        //         ContentState.createFromBlockArray(
                        //             contentBlock.contentBlocks
                        //         )
                        //     const newEditorState =
                        //         EditorState.createWithContent(contentState1)
                        //     setEditorState(newEditorState)
                        //     setContentState(res?.data?.description)
                        // }
                    }
                })
                .catch((err) => {
                    setLoading(false)
                })
        } else {
            const objBlogData = {
                title: '',
                category: null,
                // short_description: '',
                status: 'Draft',
                type: 'blog',
                subtype_category: null,
                button_link: null,
                button_title: null,
                location: null,
                tag: null,
            }

            for (const key in objBlogData) {
                if (Object.hasOwnProperty.call(objBlogData, key)) {
                    setValue(key, objBlogData[key])
                }
            }
        }
        setLoading(false)
    }, [])

    return !loading ? (
        <Grid className="customJoditPadding" container>
            <Grid container spacing={2} className={style.box}>
                <Grid container item xs={9}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="title">
                                Title
                                <span
                                    style={{
                                        color: '#dc3545',
                                    }}
                                >
                                    *
                                </span>
                            </FormLabel>

                            <TextField
                                {...register('title')}
                                id="title"
                                placeholder="Title"
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={3}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="category" sx={{ pt: 4 }}>
                                Hub Status
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingTop: '16px',
                                    paddingLeft: '12px',
                                    marginRight: '0',
                                }}
                                control={
                                    <Controller
                                        name="status"
                                        {...register('status')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="status"
                                                id="demo-simple-select-status"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                value={
                                                    field.value
                                                        ? field.value
                                                        : ''
                                                }
                                                onChange={(event) =>
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value={'0'}>
                                                    Select Hub Status
                                                </MenuItem>
                                                {hubStatusDropdown?.map(
                                                    (option) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option?.name
                                                                }
                                                                value={
                                                                    option?.name
                                                                }
                                                            >
                                                                {option?.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="category" sx={{ pt: 4 }}>
                                Category
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingTop: '16px',
                                    paddingLeft: '12px',
                                    marginRight: '0',
                                }}
                                control={
                                    <Controller
                                        name="category"
                                        {...register('category')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="category"
                                                id="demo-simple-select-category"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                value={
                                                    field.value
                                                        ? field.value
                                                        : ''
                                                }
                                                onChange={(event) =>
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value={'0'}>
                                                    Select Category
                                                </MenuItem>
                                                {categoryDropdown?.map(
                                                    (option) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option?._id
                                                                }
                                                                value={
                                                                    option?._id
                                                                }
                                                            >
                                                                {option?.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={4}>
                    <Grid item xs={12}>
                        <SubtypeSelect
                            categoryDropdown={categoryDropdown}
                            subTypeCategory={subTypeCategory}
                            register={register}
                            control={control}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={4}></Grid>
                <Grid item container xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="type" sx={{ pt: 4 }}>
                                Hub Type
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingTop: '16px',
                                    paddingLeft: '12px',
                                    marginRight: '0',
                                }}
                                control={
                                    <Controller
                                        name="type"
                                        {...register('type')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="type"
                                                id="demo-simple-select-type"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                value={
                                                    field.value
                                                        ? field.value
                                                        : ''
                                                }
                                                onChange={(event) => {
                                                    if (
                                                        event.target.value !==
                                                        watch('type')
                                                    ) {
                                                        setValue(
                                                            'button_link',
                                                            ''
                                                        )
                                                    }
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }}
                                            >
                                                <MenuItem value={'0'}>
                                                    Select Hub Type
                                                </MenuItem>
                                                {hubTypesDropdown?.map(
                                                    (option) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option?.name
                                                                }
                                                                value={
                                                                    option?.value
                                                                }
                                                            >
                                                                {option?.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="location" sx={{ pt: 4 }}>
                                Hub Location
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingTop: '16px',
                                    paddingLeft: '12px',
                                    marginRight: '0',
                                }}
                                control={
                                    <Controller
                                        name="location"
                                        {...register('location')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="location"
                                                id="demo-simple-select-location"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                value={
                                                    field.value
                                                        ? field.value
                                                        : ''
                                                }
                                                onChange={(event) => {
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }}
                                            >
                                                <MenuItem value={'0'}>
                                                    Select Hub Location
                                                </MenuItem>
                                                {hubLocationDropdownValues?.map(
                                                    (option) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option?.name
                                                                }
                                                                value={
                                                                    option?.name
                                                                }
                                                            >
                                                                {option?.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="location" sx={{ pt: 4 }}>
                                Hub Tag
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingTop: '16px',
                                    paddingLeft: '12px',
                                    marginRight: '0',
                                }}
                                control={
                                    <Controller
                                        name="tag"
                                        {...register('tag')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="tag"
                                                id="demo-simple-select-tag"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                value={
                                                    field.value
                                                        ? field.value
                                                        : ''
                                                }
                                                onChange={(event) => {
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }}
                                            >
                                                <MenuItem value={'0'}>
                                                    Select Hub Tag
                                                </MenuItem>
                                                {hubTagsDropdownValues?.map(
                                                    (option) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option?.name
                                                                }
                                                                value={
                                                                    option?.name
                                                                }
                                                            >
                                                                {option?.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container item xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="title" sx={{ pt: 4 }}>
                                Button Title
                                {watch('type')?.toLowerCase() === 'quiz'
                                    ? null
                                    : '(Optional)'}
                            </FormLabel>
                            <TextField
                                {...register('button_title')}
                                id="button_title"
                                placeholder="Button Title"
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container item xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="button_link" sx={{ pt: 4 }}>
                                Button Link
                                {watch('type')?.toLowerCase() === 'quiz'
                                    ? null
                                    : '(Optional)'}
                            </FormLabel>
                            {watch('type')?.toLowerCase() === 'quiz' ? (
                                <FormControlLabel
                                    style={{
                                        paddingTop: '16px',
                                        paddingLeft: '12px',
                                        marginRight: '0',
                                    }}
                                    control={
                                        <Controller
                                            name="type"
                                            {...register('button_link')}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    labelId="button_link"
                                                    id="demo-simple-select-type"
                                                    fullWidth
                                                    margin="normal"
                                                    size="small"
                                                    value={
                                                        field.value
                                                            ? field.value
                                                            : ''
                                                    }
                                                    onChange={(event) => {
                                                        field.onChange(
                                                            event.target.value
                                                        )
                                                    }}
                                                >
                                                    <MenuItem value={'0'}>
                                                        Select Quiz
                                                    </MenuItem>
                                                    {quizDropdown?.map(
                                                        (option) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        option?._id
                                                                    }
                                                                    value={
                                                                        option?._id
                                                                    }
                                                                >
                                                                    {
                                                                        option?.title
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                                </Select>
                                            )}
                                        />
                                    }
                                />
                            ) : (
                                <>
                                    <TextField
                                        {...register('button_link')}
                                        id="button_link"
                                        placeholder="Button Link"
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />

                                    <Typography
                                        style={{ fontSize: 14 }}
                                        variant="body1"
                                    >
                                        e.g: reddi://attachmentquiz/ID,
                                        reddi://match-makeme-questions
                                    </Typography>
                                </>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{ minHeight: '330px' }}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            marginTop: '10',
                            border: `2px solid rgba(5, 6, 15, 0.7)`,
                            borderRadius: '4px',
                        }}
                    >
                        <JoditEditor
                            ref={editorJodit}
                            value={contentJodit}
                            config={{
                                controls: {
                                    font: {
                                        list: {
                                            'Open Sans, sans-serif':
                                                'Open Sans',
                                            'tiempos-headline':
                                                'TiemposHeadline',
                                        },
                                    },
                                    fontsize: {
                                        list: [
                                            '8',
                                            '9',
                                            '10',
                                            '11',
                                            '12',
                                            '14',
                                            '16',
                                            '18',
                                            '20',
                                            '22',
                                            '24',
                                            '30',
                                            '32',
                                            '34',
                                            '36',
                                            '48',
                                            '60',
                                            '72',
                                            '96',
                                        ],
                                    },
                                },
                                uploader: {
                                    insertImageAsBase64URI: false,
                                    withCredentials: false,
                                    // format: 'json',
                                    method: 'POST',
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                    url: `${process.env.REACT_APP_BASE_URL}/admin/hub/upload-image-html-editor`,
                                    prepareData: function (data) {
                                        return data
                                    },
                                    processFileName: function (
                                        key,
                                        file,
                                        name
                                    ) {
                                        key = 'images'
                                        return [key, file]
                                    },
                                    isSuccess: function (resp) {
                                        return !resp.error
                                    },
                                    getMsg: function (resp) {
                                        return resp.msg.join !== undefined
                                            ? resp.msg.join(' ')
                                            : resp.msg
                                    },
                                    process: function (resp) {
                                        return {
                                            files: [resp.data],
                                            path: '',
                                            baseurl: '',
                                            error: resp.error ? 1 : 0,
                                            msg: resp.msg,
                                        }
                                    },
                                    defaultHandlerSuccess: function (
                                        data,
                                        resp
                                    ) {
                                        const files = data.files || []

                                        if (files.length) {
                                            files[0].forEach((item) => {
                                                this.selection.insertImage(
                                                    item,
                                                    250,
                                                    250
                                                )
                                            })
                                        }
                                    },
                                    defaultHandlerError: function (resp) {
                                        this.events.fire(
                                            'errorPopap',
                                            this.i18n(resp?.message ?? '')
                                        )
                                    },
                                },
                                readonly: false, // all options from https://xdsoft.net/jodit/docs/,
                                placeholder: 'Description',
                                speechRecognize: false,
                                showStatusbar: false,
                                showCharsCounter: false,
                                showWordsCounter: false,
                                showXPathInStatusbar: false,
                                disablePlugins: 'add-new-line',
                                askBeforePasteHTML: false,
                                askBeforePasteFromWord: false,
                                defaultActionOnPaste: 'insert_clear_html',
                                buttons:
                                    'bold, italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,classSpan,image,cut,copy,paste,selectall,hr,link, symbols, indent,outdent, left, brush, undo, redo,source, fullsize, preview',
                            }}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) => setContentJodit(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={(newContent) => {}}
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{ marginTop: '16px' }}>
                    <Grid item xs={12}>
                        <UserImageUploadSection
                            images={userImages}
                            setUserImages={handleSetUserImages}
                            blobImageLocalURL={blobImageLocalURL}
                            setBlobImageLocalURL={setBlobImageLocalURL}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} container justifyContent="flex-end">
                    <ButtonCore
                        disabled={buttonLoading}
                        variant="contained"
                        style={{
                            borderRadius: 8,
                            background: '#BA5A6B',
                            color: '#FFFFFF',
                            marginLeft: '8px',
                        }}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {buttonLoading ? (
                            <CircularProgress color="inherit" size={25} />
                        ) : (
                            'Submit'
                        )}
                    </ButtonCore>
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Adjust this as needed
                }}
            >
                <CircularProgress />
            </div>
        </>
    )
}

export default BlogModule
